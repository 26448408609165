export const races = [
    {
        date: '9/7/2024',
        name: 'Terrapin Time Trial',
        location: 'Greenbelt, MD'
    },
    {
        date: '9/14/2024',
        name: 'DC Invite',
        location: 'Wheaton, MD'
    },
    {
        date: '9/21/2024',
        name: 'Cavalier Invite',
        location: 'Earlysville, VA'
    },
    {
        date: '10/5/2024',
        name: 'Paul Short Run',
        location: 'Bethlehem, PA'
    },
    {
        date: '10/19/2024',
        name: 'NIRCA Mid-Atlantic Regionals',
        location: 'Bethlehem, PA'
    },
    {
        date: '11/9/2024',
        name: 'NIRCA Nationals',
        location: 'Hershey, PA'
    },
]